import React from "react"
import { I18nProvider } from "@lingui/react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import catalogES from "../locales/es/messages"
import Layout from "../components/layout"
import startupedia from "../assets/images/home/startupedia.png"
import kokowinka from "../assets/images/home/kokowinka.png"
import inddubio from "../assets/images/home/inddubio.png"
import unaceite from "../assets/images/home/unaceite.png"
import luisjuarros from "../assets/images/home/luisjuarros.jpg"

export default ({ data }) => (
  <I18nProvider
    language="es"
    catalogs={{
      es: catalogES,
    }}
  >
    <Layout
      pageType="home"
      image="/android-chrome-512x512.png"
      lang="es"
      title="Luis Juarros"
      description="Web personal de Luis Juarros"
      paths={{
        en: "/en",
        it: "/it",
        br: "/br",
        fr: "/fr",
        de: "/de",
        es: "",
      }}
    >
      {/* <div style={{"background": "url(https://images.unsplash.com/photo-1548685913-fe6678babe8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1323&q=80)", "box-shadow": "inset 0 0 0 2000px rgba(0, 0, 0, 0)"}} className="page-holder bg-cover"> */}
      <div>
        <div className="container py-5">
          <header className="text-left text-white py-5">
            <div className="row">
              <div className="col-md-5 text-right  d-none d-md-block">
                <img
                  style={{
                    borderRadius: "50%",
                    "box-shadow": "0 3px 10px rgba(0, 0, 0, 0.5)",
                  }}
                  src="https://images.ctfassets.net/1wyfxuvyjzjn/3rDaPD0tilSOqzlhAH2H6K/e5d97c0bc789bf008747cc034c57120f/luis_juarros.JPG?h=300"
                />
              </div>
              <div
                className="col-md-7 d-none d-md-block"
                style={{ paddingLeft: "2rem" }}
              >
                <p style={{ color: "gray" }} className="lead mt-1 mb-1">
                  Desarrollador de Software, Profe y Tío de Trillizos
                </p>
                <h1
                  className="display-4 text-black font-weight-bold mb-1"
                  style={{ color: "tomato" }}
                >
                  Luis Juarros
                </h1>
                <p
                  style={{ color: "black", fontSize: "1rem" }}
                  className="lead mb-4"
                >
                  Ayudo a transformar necesidades de negocio en software que
                  funciona. Esto no lo hago sólo, sino con la ayuda de
                  ingenieros a los que me gusta tratar bien y sacar lo mejor de
                  ellos, haciendo brillar sus fortalezas e identificando y
                  acotando sus debilidades para que se sientan cómodos.
                </p>
                <a
                  href="https://drive.google.com/file/d/1h-TOakPvmBD9pnI1bslI_JXK60qDPgsD/view?usp=sharing"
                  target="_blank"
                  className="btn btn-danger active"
                  role="button"
                  aria-pressed="true"
                  style={{ backgroundColor: "tomato" }}
                >
                  Descargar CV
                </a>{" "}
                &nbsp;
                <Link
                  to="/contacto/"
                  className="btn btn-light active"
                  role="button"
                  aria-pressed="true"
                  style={{ backgroundColor: "lightgray", color: "black" }}
                >
                  Contactar
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 text-center d-sm-block d-md-none">
                <img
                  style={{
                    borderRadius: "50%",
                    "box-shadow": "0 3px 10px rgba(0, 0, 0, 0.5)",
                  }}
                  src="https://images.ctfassets.net/1wyfxuvyjzjn/3rDaPD0tilSOqzlhAH2H6K/e5d97c0bc789bf008747cc034c57120f/luis_juarros.JPG?h=300"
                />
              </div>
              <div
                className="col-sm-12 d-sm-block d-md-none text-center"
                style={{ paddingLeft: "2rem" }}
              >
                <p style={{ color: "gray" }} className="lead mt-5 mb-1">
                  Desarrollador de Software, Profe y Tío de Trillizos
                </p>
                <h1
                  className="display-4 text-black font-weight-bold mb-1"
                  style={{ color: "tomato" }}
                >
                  Luis Juarros
                </h1>
                <p
                  style={{ color: "black", fontSize: "1rem" }}
                  className="lead mb-4"
                >
                  Ayudo a transformar necesidades de negocio en software que
                  funciona. Esto no lo hago sólo, sino con la ayuda de
                  ingenieros a los que me gusta tratar bien y sacar lo mejor de
                  ellos, haciendo brillar sus fortalezas e identificando y
                  acotando sus debilidades para que se sientan cómodos.
                </p>
                <a
                  href="https://drive.google.com/file/d/1h-TOakPvmBD9pnI1bslI_JXK60qDPgsD/view?usp=sharing"
                  target="_blank"
                  className="btn btn-danger active"
                  role="button"
                  aria-pressed="true"
                  style={{ backgroundColor: "tomato" }}
                >
                  Descargar CV
                </a>{" "}
                &nbsp;
                <Link
                  to="/contacto/"
                  className="btn btn-light active"
                  target="_blank"
                  role="button"
                  aria-pressed="true"
                  style={{ backgroundColor: "lightgray", color: "black" }}
                >
                  Contactar
                </Link>
              </div>
            </div>
          </header>
        </div>
      </div>

      <div className="wrapper mt-3">
        <div className="container">
          <h2>Proyectos Actuales</h2>

          <div className="row">
            <div className="col-md-6 col-lg-6 mt-5">
              <a
                href="https://startupedia.net/"
                title="Startupedia"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "dimgrey",
                  fontSize: "0.9rem",
                }}
              >
                <div className="card">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/1wyfxuvyjzjn/18uorkfjro6wX6mZPe9PRX/f19e4a1f9a3dc8625010107ebf8a5108/startupedia.png?w=800&h=600&fm=webp&q=80"
                    style={{ "box-shadow": "0 3px 10px rgba(0, 0, 0, 0.5)" }}
                  />
                  <div className="card-body">
                    <h2 className="card-title">Startupedia</h2>
                    <p className="card-text">
                      La Enciclopedia sobre Startups y Emprendimiento
                      <br />
                      <i>Gatsby - Contentful - Netlify</i>
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-6 mt-5">
              <a
                href="https://unaceite.com/"
                title="UnAceite"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "dimgrey",
                  fontSize: "0.9rem",
                }}
              >
                <div className="card">
                  <img
                    src="https://images.ctfassets.net/1wyfxuvyjzjn/77OpVgh4AEjXItQHmAyysA/f5bc5315b9b22ef16ffa8c3babe1db89/unaceite.png?w=800&h=600&fm=webp&q=80"
                    className="img-fluid"
                    style={{ "box-shadow": "0 3px 10px rgba(0, 0, 0, 0.5)" }}
                  />
                  <div className="card-body">
                    <h2 className="card-title">UnAceite</h2>
                    <p className="card-text">
                      Web 100% Automática sobre Aceites
                      <br />
                      <i>NodeJS - DeepL - Netlify</i>
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <h2 className="pt-5">Proyectos Pasados</h2>
          <div className="row">
            <div className="col-md-6 col-lg-6 mt-5">
              <a
                href="https://kokowinka.com/"
                title="Kokowinka"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "dimgrey",
                  fontSize: "0.9rem",
                }}
              >
                <div className="card">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/1wyfxuvyjzjn/2IjawRlev4xvicdzlTJ45y/64916169eaa91b7f841e073471266778/kokowinka.png?w=800&h=600&fm=webp&q=80"
                    style={{ "box-shadow": "0 3px 10px rgba(0, 0, 0, 0.5)" }}
                  />
                  <div className="card-body">
                    <h2 className="card-title">Kokowinka</h2>
                    <p className="card-text">
                      Tienda de moda con rebajas a partir del -40%
                      <br />
                      <i>MeteorJS - Python - Mongo</i>
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-6 col-lg-6 mt-5">
              <a
                href="https://inddubio.com/"
                title="Inddubio"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "dimgrey",
                  fontSize: "0.9rem",
                }}
              >
                <div className="card">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/1wyfxuvyjzjn/1smsrHH3tm1hdAY5E60HlR/514d5a5f4b1272e637cec2bf58cd7ade/inddubio.png?w=800&h=600&fm=webp&q=80"
                    style={{ "box-shadow": "0 3px 10px rgba(0, 0, 0, 0.5)" }}
                  />
                  <div className="card-body">
                    <h2 className="card-title">Inddubio</h2>
                    <p className="card-text">
                      Logiciel Collaboratif de Gestion de Projets Juridiques
                      <br />
                      <i>Python - NodeJS - PostgreSQL</i>
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <h2>Últimos Artículos del Blog</h2>
          <div className="row">
            {/*<div className="col-sm-12 col-lg-9 col-xl-9">*/}
            <div className="col-12">
              <div className="container">
                <div className="row">
                  {data.allContentfulBlogPost.edges
                    .slice(0, 10)
                    .map(({ node }, index) => {
                      return (
                        <>
                          {/*<div className="col-md-6 mt-5">*/}
                          <div className="col-md-6 col-lg-4 mt-5">
                            <Link
                              to={`${getBlogPath(node.lang)}/${node.slug}/`}
                              title={`${getBlogPath(node.lang)}/${node.slug}/`}
                              style={{
                                textDecoration: "none",
                                color: "dimgrey",
                                fontSize: "0.9rem",
                              }}
                            >
                              <div className="card">
                                <Img
                                  alt={node.heroImage.title}
                                  title={node.heroImage.title}
                                  style={{ maxWidth: 450, maxHeight: 253 }}
                                  fadeIn="false"
                                  loading="eager"
                                  fluid={node.heroImage.fluid}
                                  style={{ "box-shadow": "0 3px 10px rgba(0, 0, 0, 0.5)" }}
                                />
                                <div className="card-body">
                                  <h2 className="card-title">{node.title}</h2>
                                  <p className="card-text">{node.excerpt}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 col-xl-3 mt-5 d-none">
              <img
                src="https://placehold.co/300x600"
                title="bla bla bla"
                alt="bla bla bla"
              />
              <div>&nbsp;</div>
              <img
                src="https://placehold.co/300x250"
                title="bla bla bla"
                alt="bla bla bla"
              />
            </div>
          </div>
          <p className="text-center mt-3">
            <Link to="/blog/">
              <strong style={{ color: "tomato" }}>Ver más Artículos</strong>
            </Link>
          </p>
          {/* </div>
      </div>
      <div className="col-12" style={{backgroundColor:"#283747"}}>
              <div className="row" style={{color:"white"}}>
                <div className="container">
                  <h2 style={{color:"white",marginTop:"10px"}}>Términos Populares</h2>
                  <p style={{color:"darkgray"}}>ola ka ase</p>
                </div>
                 */}
        </div>
      </div>
    </Layout>
  </I18nProvider>
)

export const query = graphql`
  query {
    allContentfulBlogPost(
      limit: 6
      sort: { order: DESC, fields: publishDate }
      filter: { lang: { eq: "es" } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            title
            description
            fluid(
              maxHeight: 600, maxWidth: 1200, quality: 60,
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}
